@charset "UTF-8";
html {
  font-size: 62.5%;
}

* {
  font-family: "Noto Sans JP", "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
}

a:hover {
  opacity: 0.6;
  cursor: pointer;
}

/*base*/
img {
  vertical-align: middle;
}

a {
  color: inherit;
  text-decoration: none;
}

body {
  line-height: 1.5;
}

/*reset*/
/* Box sizing rules */
/* Box sizingの定義 */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* tableの余白削除 */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* Remove default padding */
/* デフォルトのpaddingを削除 */
ul,
ol {
  padding: 0;
}

/* Remove default margin */
/* デフォルトのmarginを削除 */
body,
h1,
h2,
h3,
h4,
p,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

/* Set core body defaults */
/* bodyのデフォルトを定義 */
body {
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* Remove list styles on ul, ol elements with a class attribute */
/* class属性を持つul、ol要素のリストスタイルを削除 */
ul[class],
li,
ol[class] {
  list-style: none;
}

/* A elements that don't have a class get default styles */
/* classを持たない要素はデフォルトのスタイルを取得 */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
/* img要素の扱いを簡単にする */
img {
  max-width: 100%;
  display: block;
}

/* Natural flow and rhythm in articles by default */
/* article要素内の要素に自然な流れとリズムを定義 */
article > * + * {
  margin-top: 1em;
}

/* Inherit fonts for inputs and buttons */
/* inputやbuttonなどのフォントは継承を定義 */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations and transitions for people that prefer not to see them */
/* 見たくない人用に、すべてのアニメーションとトランジションを削除 */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
.button {
  width: 100%;
  display: block;
  position: relative;
}
.button:hover {
  opacity: 0.6;
}
.button-wrap {
  background-color: #fff;
  text-align: center;
  margin: 20px auto 0;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
}
@media screen and (max-width: 767px) {
  .button-wrap {
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.32);
  }
}
.button-wrap-app {
  max-width: 280px;
  border-radius: 24px;
}
.button-wrap-app .button {
  padding: 16px;
}
.button-wrap-form {
  max-width: 840px;
  border-radius: 36px;
  margin-top: 48px;
}
@media screen and (max-width: 767px) {
  .button-wrap-form {
    max-width: 280px;
  }
}
.button-wrap-form .button {
  padding: 22px 24px;
}
@media screen and (max-width: 767px) {
  .button-wrap-form .button {
    padding: 24px;
  }
}
@media screen and (max-width: 767px) {
  .button-wrap-form .button img {
    max-width: 180px;
  }
}

.column {
  display: flex;
  flex-wrap: wrap;
}
.column-two {
  gap: 40px;
}
@media screen and (max-width: 767px) {
  .column-two {
    gap: 20px;
  }
}
.column-two .column-item {
  width: calc((100% - 40px) / 2);
}
@media screen and (max-width: 767px) {
  .column-two .column-item {
    width: 100%;
    flex-direction: column;
    max-width: 295px;
    margin: auto;
  }
}
.column-two .column-image {
  object-fit: cover;
  aspect-ratio: 8/9;
  border-radius: 8px;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .column-two .column-image {
    max-width: 160px;
  }
}
@media screen and (max-width: 767px) {
  .column-two .column-image {
    aspect-ratio: 1/1;
  }
}
.column-two .column-announcer {
  background-color: #FF7F7F;
  border-radius: 8px;
  padding: 20px;
}
@media screen and (max-width: 767px) {
  .column-two .column-announcer {
    order: 0;
  }
}
.column-two .column-voiceactor {
  background-color: #DC8FD7;
  border-radius: 8px;
  padding: 20px;
}
@media screen and (max-width: 767px) {
  .column-two .column-voiceactor {
    order: 1;
  }
}
.column-three {
  gap: 10px;
}
@media screen and (max-width: 767px) {
  .column-three {
    gap: 5px;
  }
}
.column-three .column-item {
  width: calc((100% - 20px) / 3);
}
@media screen and (max-width: 767px) {
  .column-three .column-item {
    width: calc((100% - 5px) / 2);
  }
}
.column-four {
  gap: 40px;
}
.column-four .column-item {
  width: calc((100% - 120px) / 4);
}
.column-five {
  gap: 20px;
}
@media screen and (max-width: 767px) {
  .column-five {
    row-gap: 20px;
    column-gap: 5px;
  }
}
.column-five .column-item {
  width: calc((100% - 60px) / 4);
  padding: 8px;
  border-radius: 10px;
}
.column-five .column-item:hover {
  opacity: 0.6;
  cursor: pointer;
}
@media screen and (max-width: 767px) {
  .column-five .column-item {
    width: calc((100% - 15px) / 4);
    padding: 4px;
    border-radius: 5px;
  }
}
.column-five .column-item--announcer {
  background-color: #FF7F7F;
}
.column-five .column-item--voiceactor {
  background-color: #DC8FD7;
}
.column-five .column-image {
  max-width: 239px;
  object-fit: cover;
  aspect-ratio: 1/1;
  border-radius: 8px;
  margin: auto;
}
.column-profile {
  margin-top: 48px;
}
@media screen and (max-width: 1138px) and (min-width: 768px) {
  .column-profile {
    max-width: 700px;
    margin: 48px auto 0;
  }
}
@media screen and (max-width: 767px) {
  .column-profile {
    margin-top: 32px;
    flex-direction: column;
  }
}
@media screen and (max-width: 1138px) and (min-width: 768px) {
  .column-profile .card-item {
    justify-content: center;
  }
}
.column-image {
  width: 100%;
}
.column-description {
  font-size: 1.6rem;
}

/*!
	Modaal - accessible modals - v0.4.4
	by Humaan, for all humans.
	http://humaan.com
 */
.modaal-noscroll {
  overflow: hidden;
}

.modaal-accessible-hide {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden;
}

.modaal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  opacity: 0;
}

.modaal-wrapper {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  overflow: auto;
  opacity: 1;
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
  transition: all 0.3s ease-in-out;
}
.modaal-wrapper * {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-backface-visibility: hidden;
}
.modaal-wrapper .modaal-close {
  border: none;
  background: transparent;
  padding: 0;
  -webkit-appearance: none;
}
.modaal-wrapper.modaal-start_none {
  display: none;
  opacity: 1;
}
.modaal-wrapper.modaal-start_fade {
  opacity: 0;
}
.modaal-wrapper *[tabindex="0"] {
  outline: none !important;
}
.modaal-wrapper.modaal-fullscreen {
  overflow: hidden;
}

.modaal-outer-wrapper {
  display: table;
  position: relative;
  width: 100%;
  height: 100%;
}
.modaal-fullscreen .modaal-outer-wrapper {
  display: block;
}

.modaal-inner-wrapper {
  display: table-cell;
  width: 100%;
  height: 100%;
  position: relative;
  vertical-align: middle;
  text-align: center;
  padding: 80px 25px;
}
.modaal-fullscreen .modaal-inner-wrapper {
  padding: 0;
  display: block;
  vertical-align: top;
}

.modaal-container {
  position: relative;
  display: inline-block;
  width: 100%;
  margin: auto;
  text-align: left;
  color: #000;
  max-width: 1000px;
  border-radius: 0px;
  background: #fff;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  cursor: auto;
}
.modaal-container.is_loading {
  height: 100px;
  width: 100px;
  overflow: hidden;
}
.modaal-fullscreen .modaal-container {
  max-width: none;
  height: 100%;
  overflow: auto;
}

.modaal-close {
  position: fixed;
  right: 20px;
  top: 20px;
  color: #fff;
  cursor: pointer;
  opacity: 1;
  width: 50px;
  height: 50px;
  background: rgba(0, 0, 0, 0);
  border-radius: 100%;
  transition: all 0.2s ease-in-out;
}
.modaal-close:focus, .modaal-close:hover {
  outline: none;
  background: #fff;
}
.modaal-close:focus:before, .modaal-close:focus:after, .modaal-close:hover:before, .modaal-close:hover:after {
  background: #b93d0c;
}
.modaal-close span {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden;
}
.modaal-close:before, .modaal-close:after {
  display: block;
  content: " ";
  position: absolute;
  top: 14px;
  left: 23px;
  width: 4px;
  height: 22px;
  border-radius: 4px;
  background: #fff;
  transition: background 0.2s ease-in-out;
}
.modaal-close:before {
  transform: rotate(-45deg);
}
.modaal-close:after {
  transform: rotate(45deg);
}
.modaal-fullscreen .modaal-close {
  background: #afb7bc;
  right: 10px;
  top: 10px;
}

.modaal-content-container {
  padding: 30px;
}

.modaal-confirm-wrap {
  padding: 30px 0 0;
  text-align: center;
  font-size: 0;
}

.modaal-confirm-btn {
  font-size: 14px;
  display: inline-block;
  margin: 0 10px;
  vertical-align: middle;
  cursor: pointer;
  border: none;
  background: transparent;
}
.modaal-confirm-btn.modaal-ok {
  padding: 10px 15px;
  color: #fff;
  background: #555;
  border-radius: 3px;
  transition: background 0.2s ease-in-out;
}
.modaal-confirm-btn.modaal-ok:hover {
  background: #2f2f2f;
}
.modaal-confirm-btn.modaal-cancel {
  text-decoration: underline;
}
.modaal-confirm-btn.modaal-cancel:hover {
  text-decoration: none;
  color: #2f2f2f;
}

@keyframes instaReveal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes instaReveal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes instaReveal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes instaReveal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-ms-keyframes instaReveal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.modaal-instagram .modaal-container {
  width: auto;
  background: transparent;
  box-shadow: none !important;
}
.modaal-instagram .modaal-content-container {
  padding: 0;
  background: transparent;
}
.modaal-instagram .modaal-content-container > blockquote {
  width: 1px !important;
  height: 1px !important;
  opacity: 0 !important;
}
.modaal-instagram iframe {
  opacity: 0;
  margin: -6px !important;
  border-radius: 0 !important;
  width: 1000px !important;
  max-width: 800px !important;
  box-shadow: none !important;
  animation: instaReveal 1s linear forwards;
}

.modaal-image .modaal-inner-wrapper {
  padding-left: 140px;
  padding-right: 140px;
}
.modaal-image .modaal-container {
  width: auto;
  max-width: 100%;
}

.modaal-gallery-wrap {
  position: relative;
  color: #fff;
}

.modaal-gallery-item {
  display: none;
}
.modaal-gallery-item img {
  display: block;
}
.modaal-gallery-item.is_active {
  display: block;
}

.modaal-gallery-label {
  position: absolute;
  left: 0;
  width: 100%;
  margin: 20px 0 0;
  font-size: 18px;
  text-align: center;
  color: #fff;
}
.modaal-gallery-label:focus {
  outline: none;
}

.modaal-gallery-control {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  opacity: 1;
  cursor: pointer;
  color: #fff;
  width: 50px;
  height: 50px;
  background: rgba(0, 0, 0, 0);
  border: none;
  border-radius: 100%;
  transition: all 0.2s ease-in-out;
}
.modaal-gallery-control.is_hidden {
  opacity: 0;
  cursor: default;
}
.modaal-gallery-control:focus, .modaal-gallery-control:hover {
  outline: none;
  background: #fff;
}
.modaal-gallery-control:focus:before, .modaal-gallery-control:focus:after, .modaal-gallery-control:hover:before, .modaal-gallery-control:hover:after {
  background: #afb7bc;
}
.modaal-gallery-control span {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden;
}
.modaal-gallery-control:before, .modaal-gallery-control:after {
  display: block;
  content: " ";
  position: absolute;
  top: 16px;
  left: 25px;
  width: 4px;
  height: 18px;
  border-radius: 4px;
  background: #fff;
  transition: background 0.2s ease-in-out;
}
.modaal-gallery-control:before {
  margin: -5px 0 0;
  transform: rotate(-45deg);
}
.modaal-gallery-control:after {
  margin: 5px 0 0;
  transform: rotate(45deg);
}

.modaal-gallery-next-inner {
  left: 100%;
  margin-left: 40px;
}
.modaal-gallery-next-outer {
  right: 45px;
}

.modaal-gallery-prev:before, .modaal-gallery-prev:after {
  left: 22px;
}
.modaal-gallery-prev:before {
  margin: 5px 0 0;
  transform: rotate(-45deg);
}
.modaal-gallery-prev:after {
  margin: -5px 0 0;
  transform: rotate(45deg);
}
.modaal-gallery-prev-inner {
  right: 100%;
  margin-right: 40px;
}
.modaal-gallery-prev-outer {
  left: 45px;
}

.modaal-video-wrap {
  margin: auto 50px;
  position: relative;
}

.modaal-video-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  background: #000;
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;
}
.modaal-video-container iframe,
.modaal-video-container object,
.modaal-video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.modaal-iframe .modaal-content {
  width: 100%;
  height: 100%;
}

.modaal-iframe-elem {
  width: 100%;
  height: 100%;
  display: block;
}

@media only screen and (min-width: 1400px) {
  .modaal-video-container {
    padding-bottom: 0;
    height: 731px;
  }
}
@media only screen and (max-width: 1140px) {
  .modaal-image .modaal-inner-wrapper {
    padding-left: 25px;
    padding-right: 25px;
  }
  .modaal-gallery-control {
    top: auto;
    bottom: 20px;
    transform: none;
    background: rgba(0, 0, 0, 0.7);
  }
  .modaal-gallery-control:before, .modaal-gallery-control:after {
    background: #fff;
  }
  .modaal-gallery-next {
    left: auto;
    right: 20px;
  }
  .modaal-gallery-prev {
    left: 20px;
    right: auto;
  }
}
@media screen and (max-width: 900px) {
  .modaal-instagram iframe {
    width: 500px !important;
  }
}
@media screen and (max-height: 1100px) {
  .modaal-instagram iframe {
    width: 700px !important;
  }
}
@media screen and (max-height: 1000px) {
  .modaal-inner-wrapper {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .modaal-instagram iframe {
    width: 600px !important;
  }
}
@media screen and (max-height: 900px) {
  .modaal-instagram iframe {
    width: 500px !important;
  }
  .modaal-video-container {
    max-width: 900px;
    max-height: 510px;
  }
}
@media only screen and (max-width: 600px) {
  .modaal-instagram iframe {
    width: 280px !important;
  }
}
@media only screen and (max-height: 820px) {
  .modaal-gallery-label {
    display: none;
  }
}
.modaal-loading-spinner {
  background: none;
  position: absolute;
  width: 200px;
  height: 200px;
  top: 50%;
  left: 50%;
  margin: -100px 0 0 -100px;
  transform: scale(0.25);
}

@-ms-keyframes modaal-loading-spinner {
  0% {
    opacity: 1;
    -ms-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -o-transform: scale(1.5);
    transform: scale(1.5);
  }
  100% {
    opacity: 0.1;
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
@-moz-keyframes modaal-loading-spinner {
  0% {
    opacity: 1;
    -ms-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -o-transform: scale(1.5);
    transform: scale(1.5);
  }
  100% {
    opacity: 0.1;
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
@-webkit-keyframes modaal-loading-spinner {
  0% {
    opacity: 1;
    -ms-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -o-transform: scale(1.5);
    transform: scale(1.5);
  }
  100% {
    opacity: 0.1;
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
@-o-keyframes modaal-loading-spinner {
  0% {
    opacity: 1;
    -ms-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -o-transform: scale(1.5);
    transform: scale(1.5);
  }
  100% {
    opacity: 0.1;
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes modaal-loading-spinner {
  0% {
    opacity: 1;
    -ms-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -o-transform: scale(1.5);
    transform: scale(1.5);
  }
  100% {
    opacity: 0.1;
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
.modaal-loading-spinner > div {
  width: 24px;
  height: 24px;
  margin-left: 4px;
  margin-top: 4px;
  position: absolute;
}

.modaal-loading-spinner > div > div {
  width: 100%;
  height: 100%;
  border-radius: 15px;
  background: #fff;
}

.modaal-loading-spinner > div:nth-of-type(1) > div {
  -ms-animation: modaal-loading-spinner 1s linear infinite;
  -moz-animation: modaal-loading-spinner 1s linear infinite;
  -webkit-animation: modaal-loading-spinner 1s linear infinite;
  -o-animation: modaal-loading-spinner 1s linear infinite;
  animation: modaal-loading-spinner 1s linear infinite;
  -ms-animation-delay: 0s;
  -moz-animation-delay: 0s;
  -webkit-animation-delay: 0s;
  -o-animation-delay: 0s;
  animation-delay: 0s;
}

.modaal-loading-spinner > div:nth-of-type(2) > div, .modaal-loading-spinner > div:nth-of-type(3) > div {
  -ms-animation: modaal-loading-spinner 1s linear infinite;
  -moz-animation: modaal-loading-spinner 1s linear infinite;
  -webkit-animation: modaal-loading-spinner 1s linear infinite;
  -o-animation: modaal-loading-spinner 1s linear infinite;
}

.modaal-loading-spinner > div:nth-of-type(1) {
  -ms-transform: translate(84px, 84px) rotate(45deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(45deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(45deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(45deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(45deg) translate(70px, 0);
}

.modaal-loading-spinner > div:nth-of-type(2) > div {
  animation: modaal-loading-spinner 1s linear infinite;
  -ms-animation-delay: 0.12s;
  -moz-animation-delay: 0.12s;
  -webkit-animation-delay: 0.12s;
  -o-animation-delay: 0.12s;
  animation-delay: 0.12s;
}

.modaal-loading-spinner > div:nth-of-type(2) {
  -ms-transform: translate(84px, 84px) rotate(90deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(90deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(90deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(90deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(90deg) translate(70px, 0);
}

.modaal-loading-spinner > div:nth-of-type(3) > div {
  animation: modaal-loading-spinner 1s linear infinite;
  -ms-animation-delay: 0.25s;
  -moz-animation-delay: 0.25s;
  -webkit-animation-delay: 0.25s;
  -o-animation-delay: 0.25s;
  animation-delay: 0.25s;
}

.modaal-loading-spinner > div:nth-of-type(4) > div, .modaal-loading-spinner > div:nth-of-type(5) > div {
  -ms-animation: modaal-loading-spinner 1s linear infinite;
  -moz-animation: modaal-loading-spinner 1s linear infinite;
  -webkit-animation: modaal-loading-spinner 1s linear infinite;
  -o-animation: modaal-loading-spinner 1s linear infinite;
}

.modaal-loading-spinner > div:nth-of-type(3) {
  -ms-transform: translate(84px, 84px) rotate(135deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(135deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(135deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(135deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(135deg) translate(70px, 0);
}

.modaal-loading-spinner > div:nth-of-type(4) > div {
  animation: modaal-loading-spinner 1s linear infinite;
  -ms-animation-delay: 0.37s;
  -moz-animation-delay: 0.37s;
  -webkit-animation-delay: 0.37s;
  -o-animation-delay: 0.37s;
  animation-delay: 0.37s;
}

.modaal-loading-spinner > div:nth-of-type(4) {
  -ms-transform: translate(84px, 84px) rotate(180deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(180deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(180deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(180deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(180deg) translate(70px, 0);
}

.modaal-loading-spinner > div:nth-of-type(5) > div {
  animation: modaal-loading-spinner 1s linear infinite;
  -ms-animation-delay: 0.5s;
  -moz-animation-delay: 0.5s;
  -webkit-animation-delay: 0.5s;
  -o-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

.modaal-loading-spinner > div:nth-of-type(6) > div, .modaal-loading-spinner > div:nth-of-type(7) > div {
  -ms-animation: modaal-loading-spinner 1s linear infinite;
  -moz-animation: modaal-loading-spinner 1s linear infinite;
  -webkit-animation: modaal-loading-spinner 1s linear infinite;
  -o-animation: modaal-loading-spinner 1s linear infinite;
}

.modaal-loading-spinner > div:nth-of-type(5) {
  -ms-transform: translate(84px, 84px) rotate(225deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(225deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(225deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(225deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(225deg) translate(70px, 0);
}

.modaal-loading-spinner > div:nth-of-type(6) > div {
  animation: modaal-loading-spinner 1s linear infinite;
  -ms-animation-delay: 0.62s;
  -moz-animation-delay: 0.62s;
  -webkit-animation-delay: 0.62s;
  -o-animation-delay: 0.62s;
  animation-delay: 0.62s;
}

.modaal-loading-spinner > div:nth-of-type(6) {
  -ms-transform: translate(84px, 84px) rotate(270deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(270deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(270deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(270deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(270deg) translate(70px, 0);
}

.modaal-loading-spinner > div:nth-of-type(7) > div {
  animation: modaal-loading-spinner 1s linear infinite;
  -ms-animation-delay: 0.75s;
  -moz-animation-delay: 0.75s;
  -webkit-animation-delay: 0.75s;
  -o-animation-delay: 0.75s;
  animation-delay: 0.75s;
}

.modaal-loading-spinner > div:nth-of-type(7) {
  -ms-transform: translate(84px, 84px) rotate(315deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(315deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(315deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(315deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(315deg) translate(70px, 0);
}

.modaal-loading-spinner > div:nth-of-type(8) > div {
  -ms-animation: modaal-loading-spinner 1s linear infinite;
  -moz-animation: modaal-loading-spinner 1s linear infinite;
  -webkit-animation: modaal-loading-spinner 1s linear infinite;
  -o-animation: modaal-loading-spinner 1s linear infinite;
  animation: modaal-loading-spinner 1s linear infinite;
  -ms-animation-delay: 0.87s;
  -moz-animation-delay: 0.87s;
  -webkit-animation-delay: 0.87s;
  -o-animation-delay: 0.87s;
  animation-delay: 0.87s;
}

.modaal-loading-spinner > div:nth-of-type(8) {
  -ms-transform: translate(84px, 84px) rotate(360deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(360deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(360deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(360deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(360deg) translate(70px, 0);
}

.header {
  background-color: #F28C06;
  position: fixed;
  z-index: 2;
  width: 100%;
  min-height: 64px;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
}
@media screen and (max-width: 767px) {
  .header {
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.32);
  }
}
.header_inner {
  display: flex;
  padding: 8px 76px 8px 20px;
}
@media screen and (max-width: 374px) {
  .header_inner {
    gap: 10px;
  }
}
.header_item {
  display: flex;
  align-items: center;
  gap: 20px;
}
@media screen and (max-width: 374px) {
  .header_item {
    max-width: 184px;
  }
}
@media screen and (max-width: 767px) {
  .header_item .header_button {
    width: 200px;
  }
}
.header_button {
  color: #F28C06;
  background-color: #fff;
  border-radius: 24px;
  width: 240px;
  padding: 12px;
  font-size: 1.6rem;
  text-align: center;
  letter-spacing: 0.05em;
  font-weight: 950;
  display: block;
  margin: auto;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
}
@media screen and (max-width: 767px) {
  .header_button {
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.32);
    width: 240px;
  }
}
.header_button + .header_button {
  margin-top: 20px;
}
.header_text {
  color: #fff;
  letter-spacing: 0.05em;
}
.header .sns-icon-wrapper {
  border-radius: 6px;
  padding: 8px;
  max-width: 46px;
  width: 100%;
}
.header .sns-icon-wrapper img {
  width: 100%;
}
@media screen and (max-width: 767px) {
  .header .sns-icon-wrapper img {
    min-width: 30px;
  }
}
.header .hamburger {
  display: block;
  position: fixed;
  z-index: 3;
  right: 20px;
  top: 12px;
  width: 52px;
  height: 52px;
  cursor: pointer;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .header .hamburger {
    max-width: 40px;
    height: 40px;
    width: 10.6666666667vw;
  }
}
.header .hamburger-item {
  display: block;
  position: absolute;
  width: 40px;
  height: 1px;
  left: 6px;
  background: #fff;
  transition: 0.3s ease-in-out;
}
@media screen and (max-width: 767px) {
  .header .hamburger-item {
    right: 0;
    left: auto;
    width: 10.6666666667vw;
    max-width: 40px;
  }
}
.header .hamburger-item:nth-child(1) {
  top: 4px;
}
@media screen and (max-width: 767px) {
  .header .hamburger-item:nth-child(1) {
    top: 8px;
  }
}
.header .hamburger-item:nth-child(2) {
  top: 20px;
}
.header .hamburger-item:nth-child(3) {
  top: 35px;
}
@media screen and (max-width: 767px) {
  .header .hamburger-item:nth-child(3) {
    top: 32px;
  }
}
.header .hamburger.active .hamburger-item:nth-child(1) {
  top: 16px;
  transform: rotate(-45deg);
}
.header .hamburger.active .hamburger-item:nth-child(2),
.header .hamburger.active .hamburger-item:nth-child(3) {
  top: 16px;
  transform: rotate(45deg);
}
.header .menu {
  position: fixed;
  z-index: 2;
  top: 0;
  right: 0;
  background-image: linear-gradient(180deg, rgba(149, 104, 112, 0.9) 10%, rgb(149, 104, 112) 40%);
  width: 300px;
  height: 100%;
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  pointer-events: none;
  opacity: 0;
}
.header .menu.active {
  transform: translateX(0);
  pointer-events: auto;
  opacity: 1;
}
.header .menu-wrap {
  margin: 0 auto;
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  padding: 108px 20px 56px;
}
.header .menu-item {
  list-style-type: none;
  width: 100%;
  transition: 0.4s all;
}
.header .menu-item a {
  display: block;
  color: #fff;
  text-decoration: none;
}
.header .menu-item a:hover {
  opacity: 0.6;
  cursor: pointer;
}
.header .header-text {
  position: relative;
  padding-left: 25px;
}
.header .header-text::before {
  content: "";
  position: absolute;
  background-image: url(../img/icon_05.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  width: 20px;
  height: 4px;
  z-index: 1;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
.header .copy {
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0.01em;
  line-height: 1;
  padding: 0 20px;
  position: absolute;
  bottom: 30px;
}

.footer {
  background-color: #956870;
  padding: 32px 0 80px;
}
@media screen and (max-width: 767px) {
  .footer {
    padding: 16px 0 40px;
  }
}
.footer .copy {
  color: #fff;
  font-size: 1rem;
  letter-spacing: 0.01em;
}

.main {
  padding-top: 64px;
}

.text-image-svg {
  margin: auto;
}

.kv {
  background-image: url(../img/kv_bg.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
@media screen and (max-width: 767px) {
  .kv {
    background-image: url(../img/kv_bg_sp.png);
  }
}
.kv-description {
  padding: 70px 0 40px;
}
@media screen and (max-width: 767px) {
  .kv-description {
    padding: 210px 0 20px;
  }
}
.kv-description-body {
  color: #fff;
  font-size: 2.4rem;
  text-align: center;
  font-weight: 900;
  line-height: 2;
  text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}
@media screen and (max-width: 767px) {
  .kv-description-body {
    font-size: 1.6rem;
    text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.32);
    line-height: 1.5;
  }
}
@media screen and (min-width: 768px) {
  .kv_title_image {
    margin: auto;
    max-width: 741px;
    padding: 0 20px;
  }
}
@media screen and (max-width: 767px) {
  .kv_title_image {
    width: 333px;
    margin: auto;
  }
}

.section-one {
  padding: 104px 0 120px;
}
@media screen and (max-width: 767px) {
  .section-one {
    padding: 48px 0 60px;
    overflow-x: hidden;
  }
}
.section-two {
  padding: 120px 0 80px;
}
@media screen and (max-width: 767px) {
  .section-two {
    padding: 60px 0 40px;
  }
}
.section-three {
  padding: 40px 0 120px;
}
@media screen and (max-width: 767px) {
  .section-three {
    padding: 20px 0 60px;
  }
}
.section-four {
  padding: 120px 0;
}
@media screen and (max-width: 767px) {
  .section-four {
    padding: 60px 0;
    overflow-x: hidden;
  }
}
.section-heading {
  color: #fff;
}
.section-description {
  color: #fff;
}
.section-lead {
  color: #fff;
  line-height: 1.5;
}
.section-battle {
  background-color: #582059;
  position: relative;
}
.section-battle::before {
  content: "";
  width: 820px;
  height: 810px;
  background-image: url(../img/bg_01.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% 100%;
  position: absolute;
  right: 0;
  z-index: 0;
  top: 0;
}
@media screen and (max-width: 767px) {
  .section-battle::before {
    width: 370px;
    height: 667px;
    right: -158px;
  }
}
.section-battle .section-heading--announcer {
  color: #FF7F7F;
  font-size: 6.4rem;
  width: 524px;
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 767px) {
  .section-battle .section-heading--announcer {
    font-size: 2.4rem;
  }
}
.section-battle .section-heading--voiceactor {
  color: #956870;
  font-size: 6.4rem;
  width: 524px;
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 767px) {
  .section-battle .section-heading--voiceactor {
    font-size: 2.4rem;
  }
}
.section-battle .section-description {
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.6;
  text-align: center;
  margin-top: 24px;
}
@media screen and (max-width: 767px) {
  .section-battle .section-description {
    font-size: 1.4rem;
  }
}
.section-battle .text-image--01 {
  width: 100%;
  max-width: 96px;
}
@media screen and (max-width: 767px) {
  .section-battle .text-image--01 {
    max-width: 48px;
  }
}
.section-battle .sns-icon-wrapper {
  border-radius: 4px;
  padding: 6px;
  max-width: 32px;
}
@media screen and (max-width: 767px) {
  .section-battle .sns-icon-wrapper {
    position: absolute;
    right: 0;
    top: 2px;
  }
}
.section-participate {
  background-color: #956870;
}
.section-participate .section-heading {
  font-size: 7.2rem;
  line-height: 1;
  margin-top: 20px;
}
@media screen and (max-width: 767px) {
  .section-participate .section-heading {
    margin-top: 10px;
    max-width: 268px;
  }
}
.section-participate .section-lead {
  font-size: 1.6rem;
  margin-top: 40px;
}
@media screen and (max-width: 767px) {
  .section-participate .section-lead {
    margin-top: 20px;
  }
}
.section-participate .section-notes {
  position: relative;
  color: #fff;
  font-size: 2rem;
  padding-left: 40px;
}
@media screen and (max-width: 767px) {
  .section-participate .section-notes {
    font-size: 1.2rem;
    padding-left: 23px;
  }
}
.section-participate .section-notes::before {
  content: "";
  position: absolute;
  left: 0;
  width: 32px;
  height: 32px;
  background-image: url(../img/exclamation_icon.svg);
  background-size: contain;
  background-repeat: no-repeat;
}
@media screen and (max-width: 767px) {
  .section-participate .section-notes::before {
    width: 20px;
    height: 20px;
  }
}
.section-participate .unit-wrapper {
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-top: 48px;
}
@media screen and (max-width: 767px) {
  .section-participate .unit-wrapper {
    gap: 20px;
    margin-top: 32px;
  }
}
.section-participate .unit-item {
  background-color: #fff;
  border-radius: 10px;
  position: relative;
}
.section-participate .unit-item:not(.unit-item:last-child)::after {
  position: absolute;
  background-image: url(../img/arrow_down_01.svg);
  width: 48px;
  height: 24px;
  content: "";
  bottom: -30px;
  left: 50%;
  transform: translateX(-50%);
  background-size: contain;
}
@media screen and (max-width: 767px) {
  .section-participate .unit-item:not(.unit-item:last-child)::after {
    width: 24px;
    height: 12px;
    bottom: -16px;
  }
}
.section-present {
  background-color: #956870;
}
.section-present .section-heading {
  font-size: 7.2rem;
  line-height: 1;
}
@media screen and (max-width: 767px) {
  .section-present .section-heading {
    font-size: 3.2rem;
  }
}
.section-present .section-lead {
  font-size: 2.4rem;
  margin: 20px 0 24px;
}
@media screen and (max-width: 767px) {
  .section-present .section-lead {
    font-size: 1.6rem;
    margin-bottom: 0;
  }
}
.section-present .arrow-svg {
  margin-left: 60px;
  margin-bottom: 10px;
}
@media screen and (max-width: 767px) {
  .section-present .arrow-svg {
    width: 27px;
    margin: 0;
  }
}
.section-present .section-sub-heading {
  font-size: 4.8rem;
}
@media screen and (max-width: 767px) {
  .section-present .section-sub-heading {
    font-size: 3.2rem;
  }
}
.section-present .arrow-icon-one {
  margin: auto;
  padding: 8px 0 4px;
}
@media screen and (max-width: 767px) {
  .section-present .arrow-icon-one {
    padding: 4px 0 2px;
    width: 12px;
  }
}
.section-present .ranking-contents-area {
  margin: 48px 0 80px;
}
@media screen and (max-width: 767px) {
  .section-present .ranking-contents-area {
    margin: 32px 0 40px;
  }
}
.section-present .ranking-contents-area .column-card--voiceactor {
  padding-top: 40px;
}
@media screen and (max-width: 767px) {
  .section-present .ranking-contents-area .column-card--voiceactor {
    padding-top: 20px;
  }
}
.section-present .card-inner {
  padding: 32px;
  display: none;
  color: #fff;
  border-radius: 0 10px 10px 10px;
}
@media screen and (max-width: 767px) {
  .section-present .card-inner {
    padding: 20px;
  }
}
.section-present .card-inner--announcer {
  background-color: #FF7F7F;
}
.section-present .card-inner--voiceactor {
  background-color: #DC8FD7;
}
.section-present .card-inner .column-ranking {
  gap: 24px;
}
@media screen and (max-width: 786px) {
  .section-present .card-inner .column-ranking {
    flex-direction: column;
    gap: 16px;
  }
}
.section-present .card-inner .card-name {
  font-size: 4rem;
  line-height: 1.2;
  font-weight: 900;
}
@media screen and (max-width: 767px) {
  .section-present .card-inner .card-name {
    font-size: 2.4rem;
    line-height: 1.5;
  }
}
.section-present .card-inner .card-point {
  font-size: 1.6rem;
  font-weight: 700;
  margin: 20px 0 16px;
  position: relative;
  padding-left: 28px;
}
.section-present .card-inner .card-point::before {
  content: "";
  position: absolute;
  background-image: url(../img/icon_04.svg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% 100%;
  width: 24px;
  height: 24px;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
.section-present .card-inner .card-ranking {
  width: calc((100% - 96px) / 5);
}
@media screen and (max-width: 786px) {
  .section-present .card-inner .card-ranking {
    width: 100%;
  }
}
.section-present .card-inner .card-ranking-title {
  font-size: 1.4rem;
  position: relative;
  display: inline-block;
  padding: 0 2px 2px;
  font-weight: 900;
}
.section-present .card-inner .card-ranking-title::before {
  content: "";
  position: absolute;
  border-bottom: solid 3px #fff;
  width: 100%;
  bottom: -2px;
  left: 0;
}
.section-present .card-inner .card-ranking-text {
  font-size: 1.4rem;
  padding-top: 16px;
  border-top: solid 1px #fff;
  font-weight: 700;
}
.section-present .card-inner.is-active {
  display: block;
  margin-top: 20px;
  position: relative;
}
@media screen and (max-width: 767px) {
  .section-present .card-inner.is-active {
    margin-top: 10px;
  }
}
.section-present .sns-icon-ranking {
  position: absolute;
  right: 32px;
  top: 32px;
  width: 40px;
  height: 40px;
  border-radius: 5px;
  padding: 7px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 767px) {
  .section-present .sns-icon-ranking {
    width: 32px;
    height: 32px;
    padding: 5px;
    right: 20px;
    top: 20px;
  }
}
.section-present .sns-icon-ranking:hover {
  opacity: 0.6;
}
.section-present .column-item.current {
  position: relative;
  border-radius: 10px 10px 0 0;
}
.section-present .column-item.current::before {
  content: "";
  width: 100%;
  bottom: -27px;
  left: 0;
  height: 28px;
  position: absolute;
}
@media screen and (max-width: 767px) {
  .section-present .column-item.current::before {
    bottom: -17px;
    height: 18px;
  }
}
.section-present .column-item--announcer.current::before {
  background-color: #FF7F7F;
}
.section-present .column-item--voiceactor.current::before {
  background-color: #DC8FD7;
}
.section-present .all-benefits {
  border: #fff solid 1px;
  text-align: center;
  padding: 12px;
  margin-top: 20px;
}
.section-present .all-benefits-body {
  font-size: 1.4rem;
}
.section-present .all-benefits-text {
  line-height: 1.42;
}
.section-present .all-benefits-text + .all-benefits-text {
  position: relative;
  font-weight: 700;
}
.section-present .all-benefits-text + .all-benefits-text::before {
  content: "";
  border-left: 3px solid #fff;
  height: 24px;
  margin: 0 16px;
}
.section-present .all-benefits-text-bold {
  font-weight: 900;
}
.section-present .button-wrap-app {
  margin-top: 24px;
}
.section-point {
  background-color: #582059;
  position: relative;
}
.section-point::before {
  content: "";
  width: 820px;
  height: 810px;
  background-image: url(../img/bg_01.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% 100%;
  position: absolute;
  right: 0;
  z-index: 0;
  top: 0;
}
@media screen and (max-width: 767px) {
  .section-point::before {
    width: 370px;
    height: 667px;
    right: -158px;
  }
}
.section-point .section-heading {
  font-size: 7.2rem;
}
@media screen and (max-width: 767px) {
  .section-point .section-heading {
    font-size: 3.2rem;
  }
}
.section-point .section-lead {
  font-size: 2.4rem;
  margin-top: 20px;
}
@media screen and (max-width: 767px) {
  .section-point .section-lead {
    font-size: 1.6rem;
  }
}
.section-point .detail-wrapper {
  background-color: #DC8FD7;
  padding: 32px 20px;
  margin-top: 48px;
}
@media screen and (max-width: 767px) {
  .section-point .detail-wrapper {
    margin-top: 32px;
  }
}
.section-point .detail-inner {
  border: solid 4px #582059;
  border-radius: 10px;
  max-width: 840px;
  margin: auto;
  padding: 32px;
}
@media screen and (max-width: 767px) {
  .section-point .detail-inner {
    padding: 12px;
  }
}
.section-point .detail-inner + .detail-inner {
  margin-top: 48px;
  position: relative;
}
@media screen and (max-width: 767px) {
  .section-point .detail-inner + .detail-inner {
    margin-top: 24px;
  }
}
.section-point .detail-inner + .detail-inner::before {
  content: "";
  top: -68px;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  background-image: url(../img/icon_plus_01.png);
  width: 80px;
  height: 80px;
  background-repeat: no-repeat;
  background-size: contain;
}
@media screen and (max-width: 767px) {
  .section-point .detail-inner + .detail-inner::before {
    width: 44px;
    height: 44px;
    top: -38px;
  }
}
.section-point .detail-heading {
  margin-bottom: 32px;
}
@media screen and (max-width: 767px) {
  .section-point .detail-heading {
    margin-bottom: 16px;
  }
}
.section-point .detail-heading-marker {
  background: linear-gradient(transparent 70%, #fff 50%);
  padding: 0 6px;
  background-position-y: -8px;
}
.section-point .detail-text {
  color: #fff;
  font-size: 2rem;
  line-height: 1.6;
  text-align: center;
  background-color: #582059;
  border-radius: 10px;
  padding: 8px;
  font-weight: 900;
}
@media screen and (max-width: 767px) {
  .section-point .detail-text {
    font-size: 1.6rem;
    line-height: 1.5;
  }
}

.inner {
  max-width: 1120px;
  margin: auto;
  padding: 0 20px;
  position: relative;
}

.sns-icon-wrapper {
  background: #FFF;
  box-shadow: 0px 2.667px 5.333px 0px rgba(0, 0, 0, 0.16);
  display: block;
  margin-left: auto;
}
@media screen and (max-width: 767px) {
  .sns-icon-wrapper {
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.32);
  }
}

.profile-name {
  color: #fff;
  font-size: 3.2rem;
  font-weight: 900;
}
@media screen and (max-width: 767px) {
  .profile-name {
    font-size: 2.4rem;
    margin-bottom: 8px;
  }
}
.profile-dl {
  display: flex;
  font-size: 1.4rem;
  line-height: 1.4;
  width: 100%;
  border-bottom: #fff solid 1px;
  padding: 8px 0 3px;
}
.profile-dt, .profile-dd {
  color: #fff;
}
.profile-dt {
  width: 80px;
}
.profile-dd {
  position: relative;
  flex: 1;
  padding-left: 24px;
}
.profile-dd .profile-icon {
  line-height: 1.6;
}
.profile-icon {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
@media screen and (max-width: 767px) {
  .profile-icon {
    top: 0;
    transform: none;
  }
}
.profile-text-wrapper {
  width: 300px;
}
@media screen and (max-width: 767px) {
  .profile-text-wrapper {
    position: relative;
    width: 100%;
  }
}

.card-item {
  gap: 20px;
}
@media screen and (max-width: 767px) {
  .card-item picture {
    width: 100%;
  }
}

.contents-wrapper-guid {
  background-color: #DC8FD7;
}
.contents-wrapper-guid .contents-inner {
  position: relative;
}
@media screen and (min-width: 768px) {
  .contents-wrapper-guid .contents-inner {
    display: flex;
  }
}
.contents-wrapper-guid .contents-image {
  background-image: url(../img/banner_image_01.png);
  content: "";
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
@media screen and (min-width: 768px) {
  .contents-wrapper-guid .contents-image {
    height: 320px;
    width: 50%;
  }
}
@media screen and (max-width: 767px) {
  .contents-wrapper-guid .contents-image {
    width: 100%;
    height: 100%;
    position: absolute;
  }
}
.contents-wrapper-guid .contents-lead {
  font-size: 2rem;
  line-height: 1.6;
  color: #fff;
  margin: 20px 0 8px;
}
@media screen and (max-width: 767px) {
  .contents-wrapper-guid .contents-lead {
    font-size: 1.6rem;
    margin: 16px 0 8px;
  }
}
.contents-wrapper-guid .contents-button {
  width: 100%;
  padding: 10px 0;
  display: inline-block;
}
@media screen and (max-width: 767px) {
  .contents-wrapper-guid .contents-button {
    padding: 13px 0;
  }
}
.contents-wrapper-guid .contents-button:hover {
  opacity: 0.6;
}
.contents-wrapper-guid .contents-button-wrapper {
  border: solid 2px #F28C0F;
  border-radius: 24px;
  max-width: 280px;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .contents-wrapper-guid .contents-button-wrapper {
    max-width: 240px;
    margin: auto;
  }
}
.contents-wrapper-guid .contents-text-wrapper {
  width: 560px;
  padding: 68px 80px;
}
@media screen and (max-width: 767px) {
  .contents-wrapper-guid .contents-text-wrapper {
    width: 100%;
    padding: 18px 20px;
    position: relative;
    z-index: 1;
    text-align: center;
  }
}

.note-area {
  background-color: #956870;
  padding: 80px 0;
}
@media screen and (max-width: 767px) {
  .note-area {
    padding: 40px 0 60px;
  }
}
@media screen and (max-width: 767px) {
  .note-area .text-image-svg {
    max-width: 82px;
  }
}
.note-area .modal-button-note {
  color: #F28C0F;
  background-color: #fff;
  width: 100%;
  border-radius: 36px;
  border: none;
  padding: 24px 18px;
  display: block;
  max-width: 320px;
  margin: auto;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
}
@media screen and (max-width: 767px) {
  .note-area .modal-button-note {
    padding: 26px 18px;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.32);
  }
}
.note-area .modal-button-note:hover {
  opacity: 0.6;
  cursor: pointer;
}
.note-area .modal-button-rule, .note-area .modal-button-info {
  border-bottom: 1px solid #fff;
  padding-bottom: 8px;
}
.note-area .modal-button-wrapper {
  display: flex;
  gap: 35px;
  justify-content: center;
  margin-top: 38px;
}
@media screen and (max-width: 767px) {
  .note-area .modal-button-wrapper {
    flex-direction: column;
    align-items: center;
    gap: 16px;
  }
}

.modaal-close {
  display: none;
}

.modaal-wrapper.modaal-start_fade {
  pointer-events: none;
}

.modal-content {
  display: none;
}

.modaal-container {
  max-width: 1080px;
}

.modaal-content-container {
  background-color: #956870;
  width: 100%;
  position: relative;
}

.modal-text {
  color: #fff;
}

.modal-close-button {
  content: "";
  position: absolute;
  top: 32px;
  right: 32px;
  width: 40px;
  height: 32px;
}
@media screen and (max-width: 767px) {
  .modal-close-button {
    top: 16px;
    right: 16px;
  }
}
.modal-close-button::before, .modal-close-button::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1px; /* 棒の幅（太さ） */
  height: 42px; /* 棒の高さ */
  background: #fff;
}
.modal-close-button::before {
  transform: translate(-50%, -50%) rotate(45deg);
}
.modal-close-button::after {
  transform: translate(-50%, -50%) rotate(-45deg);
}
.modal-close-button:hover {
  opacity: 0.6;
  cursor: pointer;
}

.modal-list {
  padding-left: 1em;
  text-indent: -1em;
}
.modal-list-number {
  padding-left: 1.3em;
  text-indent: -1.3em;
}

.modaal-wrapper ::-webkit-scrollbar {
  background: rgba(0, 0, 0, 0.5);
  width: 15px;
  height: 15px;
  border-radius: 20px;
}
@media screen and (max-width: 767px) {
  .modaal-wrapper ::-webkit-scrollbar {
    width: 2px;
  }
}
.modaal-wrapper ::-webkit-scrollbar-thumb {
  background-color: #fff;
  border-radius: 20px;
}

.page-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  transition: opacity 2s ease;
}

.page-overlay.hidden {
  opacity: 0;
  z-index: -1;
}

.modaal-content-container:has(.modal-loading-inner) {
  padding: 96px 64px 64px;
}
@media screen and (max-width: 767px) {
  .modaal-content-container:has(.modal-loading-inner) {
    padding: 96px 16px 64px;
  }
}

.modal-loading-inner .copy {
  color: #fff;
  font-size: 1rem;
  text-align: center;
  font-weight: 500;
  margin-top: 16px;
  letter-spacing: 0.01em;
  line-height: 1;
}
.modal-loading-inner .modal-loading-image {
  margin: auto;
  max-width: 468px;
}
@media screen and (max-width: 767px) {
  .modal-loading-inner .modal-loading-image {
    max-width: 234px;
  }
}
.modal-loading-inner .modal-text {
  font-size: 3.2rem;
  text-align: center;
  font-weight: 900;
  margin-top: 32px;
}
@media screen and (max-width: 767px) {
  .modal-loading-inner .modal-text {
    font-size: 2rem;
  }
}

.modaal-content-container:has(.modal-note-inner) {
  padding: 96px 44px 64px 64px;
}
@media screen and (max-width: 767px) {
  .modaal-content-container:has(.modal-note-inner) {
    padding: 64px 16px;
  }
}

@media screen and (max-width: 767px) {
  .modaal-inner-wrapper {
    padding: 40px 20px;
  }
}

.modal-note-inner {
  max-height: 560px;
  overflow-y: auto;
}
.modal-note-inner .modal-title {
  font-size: 3.2rem;
  font-weight: 900;
  line-height: 1.25;
  padding-bottom: 16px;
}
@media screen and (max-width: 767px) {
  .modal-note-inner .modal-title {
    font-size: 2rem;
    line-height: 1.6;
  }
}
.modal-note-inner .modal-list, .modal-note-inner .modal-text-normal, .modal-note-inner .modal-list-number {
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.6;
  padding-top: 16px;
}
@media screen and (max-width: 767px) {
  .modal-note-inner .modal-list, .modal-note-inner .modal-text-normal, .modal-note-inner .modal-list-number {
    font-size: 1.6rem;
    padding-top: 8px;
  }
}
.modal-note-inner .modal-list-wrapper, .modal-note-inner .modal-text-normal-wrapper, .modal-note-inner .modal-list-number-wrapper {
  margin-bottom: 16px;
}
.modal-note-inner .modal-text-notes {
  font-size: 1.6rem;
  font-weight: 500;
}
@media screen and (max-width: 767px) {
  .modal-note-inner .modal-text-notes {
    font-size: 1.4rem;
  }
}
.modal-note-inner .modal-section-wrapper {
  display: flex;
  flex-direction: column;
  gap: 80px;
}
@media screen and (max-width: 767px) {
  .modal-note-inner .modal-section-wrapper {
    gap: 40px;
  }
}
.modal-note-inner .modal-section {
  padding-right: 20px;
}
@media screen and (max-width: 767px) {
  .modal-note-inner .modal-section {
    padding-right: 10px;
  }
}

.unit-item-body {
  padding: 24px;
  position: relative;
}
@media screen and (max-width: 767px) {
  .unit-item-body {
    padding: 14px;
  }
}
.unit-item-body img {
  padding-right: 60px;
}
@media screen and (max-width: 767px) {
  .unit-item-body img {
    padding-right: 16px;
  }
}
.unit-item-body:hover {
  opacity: 0.6;
  cursor: pointer;
}
.unit-item-body::before, .unit-item-body::after {
  content: "";
  display: inline-block;
  width: 40px;
  height: 4px;
  background-color: #FF7F7F;
  position: absolute;
  right: 20px;
  top: 50%;
  border-radius: 10px;
}
@media screen and (max-width: 767px) {
  .unit-item-body::before, .unit-item-body::after {
    width: 16px;
    height: 2px;
    right: 12px;
  }
}
.unit-item-body::before {
  transform: translateY(-50%);
  transition: opacity 0.6s;
}
.unit-item-body::after {
  transform: translateY(-50%) rotate(90deg);
  transition: transform 0.6s;
}

.unit-item-body.open::before {
  opacity: 0;
}

.unit-item-body.open::after {
  transform: translateY(-50%) rotate(180deg);
}

.accordion-contents {
  display: none;
  background-color: #DC8FD7;
  padding: 32px 64px 64px;
  border-radius: 0 0 10px 10px;
}
@media screen and (max-width: 767px) {
  .accordion-contents {
    padding: 16px 16px 32px;
  }
}

.accordion-text {
  color: #fff;
  font-size: 2.4rem;
  font-weight: 700;
  text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.32);
}
@media screen and (max-width: 767px) {
  .accordion-text {
    font-size: 1.6rem;
  }
}

.accordion-box {
  background-color: #956870;
  max-width: 840px;
  margin: 34px auto 0;
  border-radius: 10px;
}
@media screen and (max-width: 767px) {
  .accordion-box {
    margin: 16px auto 0;
  }
}
@media screen and (max-width: 767px) {
  .accordion-box .accordion-band {
    width: 132px;
  }
}

.accordion-sub-title {
  color: #fff;
  font-size: 1.8rem;
  font-weight: 900;
  line-height: 1;
  padding-left: 12px;
}
@media screen and (max-width: 767px) {
  .accordion-sub-title {
    font-size: 1.6rem;
  }
}

.accordion-sub-title-wrapper {
  display: flex;
  align-items: center;
  border: 2px solid #fff;
  padding: 10px;
  border-radius: 5px;
  width: fit-content;
}

.accordion-sub-title-icon {
  width: 20px;
}
@media screen and (max-width: 767px) {
  .accordion-sub-title-icon {
    width: 16px;
  }
}

.accordion-container {
  display: flex;
  gap: 24px;
}
@media screen and (max-width: 767px) {
  .accordion-container {
    flex-direction: column;
    gap: 12px;
  }
}

.accordion-description {
  font-size: 2rem;
  font-weight: 900;
  line-height: 1.6;
  color: #fff;
}
@media screen and (max-width: 767px) {
  .accordion-description {
    font-size: 1.6rem;
    line-height: 1.5;
  }
}
.accordion-description-wrapper {
  flex: 1;
}
.accordion-description-marker {
  background: linear-gradient(transparent 60%, #FF7F7F 40% 100%);
  display: inline;
  line-height: 1.6;
}

.marker-text-wrapper {
  margin: 16px 0 24px;
}
@media screen and (max-width: 767px) {
  .marker-text-wrapper {
    margin: 8px 0 16px;
  }
}

.accordion-wrapper {
  padding: 0 32px;
}
@media screen and (max-width: 767px) {
  .accordion-wrapper {
    padding: 0 16px;
  }
}

.accordion-inner {
  padding: 24px 0 32px;
}
@media screen and (max-width: 767px) {
  .accordion-inner {
    padding: 24px 0;
  }
}
.accordion-inner-one {
  padding: 16px 0 24px;
}
.accordion-inner-two {
  padding: 24px 0 32px;
}
@media screen and (max-width: 767px) {
  .accordion-inner-two {
    padding: 24px 0 32px;
  }
}
.accordion-inner + .accordion-inner {
  border-top: 1px solid #fff;
}

.accordion-text-normal {
  font-size: 1.6rem;
  color: #fff;
  font-weight: 700;
}
@media screen and (max-width: 767px) {
  .accordion-text-normal {
    font-size: 1.4rem;
  }
}

.accordion-notes {
  color: #fff;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.4;
  text-indent: -1em;
  padding-left: 1em;
}
@media screen and (max-width: 767px) {
  .accordion-notes {
    font-size: 1.2rem;
    line-height: 1.5;
  }
}
.accordion-notes + .accordion-notes {
  margin-top: 4px;
}

.accordion-02 .accordion-notes-wrapper {
  margin-top: 34px;
}
@media screen and (max-width: 767px) {
  .accordion-02 .accordion-notes-wrapper {
    margin-top: 16px;
  }
}

.accordion-number {
  position: relative;
  font-size: 2rem;
  color: #fff;
  padding-left: 48px;
}
@media screen and (max-width: 767px) {
  .accordion-number {
    font-size: 1.6rem;
    padding-left: 24px;
  }
}
.accordion-number + .accordion-number {
  margin-top: 8px;
}
.accordion-number::before {
  counter-increment: number 1; /* counter-resetの名前を指定、+1づつ増やす。*/
  content: counter(number) ""; /* 番号の後に、ドットを付ける場合に指定する。*/
  display: inline-block;
  background: #FF7F7F;
  border-radius: 50%;
  font-weight: 700;
  font-size: 1.8rem;
  color: white;
  left: 0;
  width: 28px;
  height: 28px;
  line-height: 1.55;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
}
@media screen and (max-width: 767px) {
  .accordion-number::before {
    width: 20px;
    height: 20px;
    font-size: 1.4rem;
    line-height: 1.42;
    top: 3px;
    transform: none;
  }
}
.accordion-number-wrapper {
  counter-reset: number 0;
}

.accordion-03 .accordion-text-normal {
  margin: 16px 0 32px;
  position: relative;
  padding-left: 33px;
}
@media screen and (max-width: 767px) {
  .accordion-03 .accordion-text-normal {
    padding-left: 24px;
    margin: 16px 0 24px;
  }
}

.fa-exclamation-triangle {
  font-size: 2rem;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
@media screen and (max-width: 767px) {
  .fa-exclamation-triangle {
    font-size: 1.6rem;
    top: 3px;
    transform: none;
  }
}

.accordion-04 .accordion-text-mark {
  margin: 16px 0 32px;
  position: relative;
  background-color: #FF7F7F;
  display: inline-block;
  padding: 4px 4px 4px 33px;
}
@media screen and (max-width: 767px) {
  .accordion-04 .accordion-text-mark {
    padding-left: 24px;
    margin: 8px 0 16px;
    line-height: 1.42;
  }
}
.accordion-04 .fa-exclamation-triangle {
  font-size: 2rem;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
@media screen and (max-width: 767px) {
  .accordion-04 .fa-exclamation-triangle {
    font-size: 1.6rem;
    top: 6px;
    transform: none;
    left: 6px;
  }
}

.point-item {
  width: calc((100% - 40px) / 2);
}
@media screen and (max-width: 767px) {
  .point-item {
    width: 100%;
    max-width: 295px;
    margin: auto;
  }
}
@media screen and (max-width: 767px) {
  .point-item--idol {
    order: 0;
  }
}
@media screen and (max-width: 767px) {
  .point-item--actress {
    order: 1;
  }
}

@media screen and (min-width: 768px) {
  /* pc非表示 */
  .u-hidden-pc {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  /* sp非表示 */
  .u-hidden-sp {
    display: none;
  }
}