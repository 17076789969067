@use "sass:map";
@use "sass:meta";
@use "sass:math";
@use "variable" as v;

//float解除用 clearfixの為にHTMLクラス付けてCSSで記述するよりCSSのみでmixinで呼び出せるようにした方が保守性向上
@mixin clearfix() {
  &::after {
    clear: both;
    content: '';
    display: block;
  }
}

// 使用するときの例
// .hoge {
//   @include g.clearfix;
// }


//positionで上下左右中央揃え
@mixin center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

// 使用するときの例（上下左右中央）
// .hoge {
//   @include g.center;
// }


//positionで上下のみ中央揃えにしたいとき
@mixin up-down-center {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
}
// 使用するときの例（上下中央）
// .hoge {
//   @include g.up-down-center;
// }


//リンクカラーの変更
@mixin linkColor($color) {
  color: $color;

  &:hover,
  &:active,
  &:focus {
    color: darken($color, 20%); //色を明るくしたいならdarkenをlightenに。
  }
}

// 使用するときの例（リンクカラー）
// a {
//   @include g.linkColor(rgb(255, 133, 51));
// }


//メディアクエリ
@mixin media-query-spfirst($breakpoint) {
  @if map-has-key(v.$breakpoints-spfirst, $breakpoint) {
    @media #{unquote(map.get(v.$breakpoints-spfirst, $breakpoint))} {
      @content;
    }
  }

  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
    + "Available breakpoints are: #{map.keys(v.$breakpoints-spfirst)}.";
  }
}

@mixin media-query-pcfirst($breakpoint) {
  @if map-has-key(v.$breakpoints-pcfirst, $breakpoint) {
    @media #{unquote(map.get(v.$breakpoints-pcfirst, $breakpoint))} {
      @content;
    }
  }

  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
    + "Available breakpoints are: #{map.keys(v.$breakpoints-pcfirst)}.";
  }
}

@mixin media-query-pc {
  @include media-query-spfirst(sm) {
    @content;
  }
}

@mixin media-query-sp {
  @include media-query-pcfirst(sm) {
    @content;
  }
}

//使用するときの例（メディアクエリ）
// .hoge {
//   @include g.media-query-pc {
//     background: blue;
//   }
// }
//   @include g.media-query-sp {
//     background-color: red;
//   }


//clump fluid font-size
@mixin clamp_fontsize($fs_min, $width_small, $width_large, $fs_max) {
  $preferred_vw_base: math.div(($fs_max - $fs_min), ($width_large - $width_small));
  $preferred_vw: ($preferred_vw_base * 100) * 1vw;
  $preferred_rem: ($width_small * -1 * $preferred_vw_base + $fs_min) * .1rem;
  font-size: clamp($fs_min * .1rem, $preferred_rem + $preferred_vw, $fs_max * .1rem);
}

//使用するときの例
//最小・最大フォントサイズをそれぞれ10px・18px、ブラウザの最小・最大幅をそれぞれ375px・768pxとしたい場合
// .hoge {
//   @include clamp_fontsize(10, 375, 768, 18);
// }


@mixin lhCrop($line-height) {
  overflow: hidden;
  &::before,
  &::after {
    content: "";
    display: block;
    width: 0;
    height: 0;
  }
  &::before {
    margin-top: calc((1 - #{$line-height}) * 0.5em);
  }
  &::after {
    margin-bottom: calc((1 - #{$line-height}) * 0.5em);
  }
}

//使用するときの例(パターン1)
//line-heightが2.5の場合
// .hoge {
//   @include g.lhCrop(2.5);
// }

//使用するときの例(パターン2)
//line-heightを変数として管理している場合($line-height-long: 2;)
// .hoge {
//   @include g.lhCrop(g.$line-height-long);
// }


@mixin triangle($direction, $width, $height, $color){
	width: 0;
	height: 0;
	border-style: solid;
	$valWidth: calc($width / 2);
	$valHeight: calc($height / 2);

	@if ($direction == top) {
		border-width: 0 $valWidth $height $valWidth;
		border-color: transparent transparent $color transparent;
	} @else if ($direction == left) {
		border-width: $valHeight $width $valHeight 0;
		border-color: transparent $color transparent transparent;
	} @else if ($direction == right) {
		border-width: $valHeight 0 $valHeight $width;
		border-color: transparent transparent transparent $color;
	} @else if ($direction == bottom) {
		border-width: $height $valWidth 0 $valWidth;
		border-color: $color transparent transparent transparent;
	}
}

//使用するときの例（右三角・縦横幅10px・カラー#fffの場合）
// @include g.triangle(right, 10px, 10px, #fff);


@mixin arrow($direction, $border-width, $width, $height, $color) {
  border: 0;
  display: inline-block;
  width: $width;
  height: $height;

  @if $direction == "top" {
    border-top: solid $border-width $color;
    border-right: solid $border-width $color;
    transform: rotate(-45deg) translateY(-50%);
  } @else if $direction == "right" {
    border-top: solid $border-width $color;
    border-right: solid $border-width $color;
    transform: rotate(45deg) translateY(-50%);
  } @else if $direction == "bottom" {
    border-bottom: solid $border-width $color;
    border-right: solid $border-width $color;
    transform: rotate(45deg) translateY(-50%);
  } @else if $direction == "left" {
    border-top: solid $border-width $color;
    border-left: solid $border-width $color;
    transform: rotate(-45deg) translateY(-50%);
  }
}
//使用するときの例（右三角・線の太さ2px・縦横幅10px・カラー#fffの場合）
// @include g.arrow(right, 2px, 10px, 10px, #fff);
