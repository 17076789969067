@use "../global" as g;

.button {
  width: 100%;
  display: block;
  position: relative;

  &:hover {
    opacity: 0.6;
  }
  
  &-wrap {
    background-color: #fff;
    text-align: center;
    margin: 20px auto 0;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);

    @include g.media-query-sp {
      box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.32);
    }

    &-app {
      max-width: 280px;
      border-radius: 24px;
      
      .button {
        padding: 16px;
      }
    }
    
    &-form {
      max-width: 840px;
      border-radius: 36px;
      margin-top: 48px;
      
      @include g.media-query-sp {
        max-width: 280px;
      }
      
      .button {
        padding: 22px 24px;
        
        @include g.media-query-sp {
          padding: 24px;
        }

        img {
          @include g.media-query-sp {
            max-width: 180px;
          }
        }
      }
    }
  }
}
