@use "../global" as g;

.header {
  background-color: #F28C06;
  position: fixed;
  z-index: 2;
  width: 100%;
  min-height: 64px;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);

  @include g.media-query-sp {
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.32);
  }
  
  &_inner {
    display: flex;
    padding: 8px 76px 8px 20px;

    @media screen and (max-width:374px) {
      gap: 10px;
    }
  }
  
  &_item {
    display: flex;
    align-items: center;
    gap: 20px;

    @media screen and (max-width:374px) {
      max-width: 184px;
    }

    .header_button {
      @include g.media-query-sp {
        width: 200px;
      }
    }
  }

  &_button {
    color: #F28C06;
    background-color: #fff;
    border-radius: 24px;
    width: 240px;
    padding: 12px;
    font-size: 1.6rem;
    text-align: center;
    letter-spacing: 0.05em;
    font-weight: 950;
    display: block;
    margin: auto;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);

    @include g.media-query-sp {
      box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.32);
      width: 240px;
    }

    +.header_button {
      margin-top: 20px;
    }
  }
  
  &_text {
    color: #fff;
    letter-spacing: 0.05em;
  }

  .sns-icon-wrapper {
    border-radius: 6px;
    padding: 8px;
      max-width: 46px;
    width: 100%;

    img {
      width: 100%;
      
      @include g.media-query-sp {
        min-width: 30px;
      }
    }
  }


  //--------------------------
  //ハンバーガーボタン
  //--------------------------
  .hamburger {
    display: block;
    position: fixed;
    z-index: 3;
    right: 20px;
    top: 12px;
    width: 52px;
    height: 52px;
    cursor: pointer;
    text-align: center;

    @include g.media-query-sp {
      max-width: 40px;
      height: 40px;

      width: calc((40 / 375) * 100vw);
    }
  }
  
  .hamburger-item {
    display: block;
    position: absolute;
    width: 40px;
    height: 1px;
    left: 6px;
    background: #fff;
    transition: 0.3s ease-in-out;

    @include g.media-query-sp {
      right: 0;
      left: auto;
      width: calc((40 / 375) * 100vw);
      max-width: 40px;
    }
  }
  
  .hamburger-item:nth-child(1) {
    top: 4px;
    
    @include g.media-query-sp {
      top: 8px;
    }
  }
  
  .hamburger-item:nth-child(2) {
    top: 20px;
  }
  
  .hamburger-item:nth-child(3) {
    top: 35px;
    
    @include g.media-query-sp {
      top: 32px;
    }
  }
  
  .hamburger.active .hamburger-item:nth-child(1) {
    top: 16px;
    transform: rotate(-45deg);
  }
  
  .hamburger.active .hamburger-item:nth-child(2),
  .hamburger.active .hamburger-item:nth-child(3) {
    top: 16px;
    transform: rotate(45deg);
  }
  
  .menu {
    position: fixed;
    z-index: 2;
    top: 0;
    right: 0;
    // background-image: linear-gradient(180deg, rgba(108, 96, 191, 0.9) 10%, rgba(108, 96, 191, 1) 40%);
    background-image: linear-gradient(180deg, rgba(149, 104, 112, 0.9) 10%, rgba(149, 104, 112, 1) 40%);
    width: 300px;
    height: 100%;
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
    pointer-events: none;
    opacity: 0;
  }
  
  .menu.active {
    transform: translateX(0);
    pointer-events: auto;
    opacity: 1;
  }
  
  .menu-wrap {
    margin: 0 auto;
    padding: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    padding: 108px 20px 56px;
  }
  
  .menu-item {
    list-style-type: none;
    width: 100%;
    transition: 0.4s all;
  }
  
  .menu-item a {
    display: block;
    color: #fff;
    text-decoration: none;

    &:hover {
      opacity: 0.6;
      cursor: pointer;
    }
  }

  .header-text {
    position: relative;
    padding-left: 25px;

    &::before {
      content: '';
      position: absolute;
      background-image: url(../img/icon_05.png);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      width: 20px;
      height: 4px;
      z-index: 1;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
  }

  .copy {
    color: #fff;
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: .01em;
    line-height: 1;
    padding: 0 20px;
    position: absolute;
    bottom: 30px;
  }
}  