@use "../global" as g;

.footer {
  background-color: #956870;
  padding: 32px 0 80px;
  
  @include g.media-query-sp {
    padding: 16px 0 40px;
  }

  .copy {
    color: #fff;
    font-size: 1rem;
    letter-spacing: .01em;
  }
}