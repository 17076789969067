@use "../global" as g;

.main {
  padding-top: 64px;
}

.text-image-svg {
  margin: auto;
}

.kv {
  background-image: url(../img/kv_bg.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  
  @include g.media-query-sp {
    background-image: url(../img/kv_bg_sp.png);
  }

  &-description {
    padding: 70px 0 40px;

    @include g.media-query-sp {
      padding: 210px 0 20px;
    }
  }

  &-description-body {
    color: #fff;
    font-size: 2.4rem;
    text-align: center;
    font-weight: 900;
    line-height: 2;
    text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    
    @include g.media-query-sp {
      font-size: 1.6rem;
      text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.32);
      line-height: 1.5;
    }
  }

  &_title_image {
    @include g.media-query-pc {
      margin: auto;
      max-width: 741px;
      padding: 0 20px;
    }

    @include g.media-query-sp {
      width: 333px;
      margin: auto;
    }
  }
}

.section {
  &-one {
    padding: 104px 0 120px;
    
    @include g.media-query-sp {
      padding: 48px 0 60px;
      overflow-x: hidden;
    }
  }
  
  &-two {
    padding: 120px 0 80px;
    
    @include g.media-query-sp {
      padding: 60px 0 40px;
    }
  }
  
  &-three {
    padding: 40px 0 120px;
    
    @include g.media-query-sp {
      padding: 20px 0 60px;
    }
  }
  
  &-four {
    padding: 120px 0;

    @include g.media-query-sp {
      padding: 60px 0;
      overflow-x: hidden;
    }
  }

  &-heading {
    color: #fff;
  }

  &-description {
    color: #fff;
  }

  &-lead {
    color: #fff;
    line-height: 1.5;
  }

  // -----------------
  // アイドルVS女優
  // -----------------
  &-battle {
    background-color: #582059;
    position: relative;
    
    &::before {
      content: '';
      width: 820px;
      height: 810px;
      background-image: url(../img/bg_01.png);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 100% 100%;
      position: absolute;
      right: 0;
      z-index: 0;
      top: 0;

      @include g.media-query-sp {
        width: 370px;
        height: 667px;
        right: -158px;
      }
    }

    .section-heading {

      &--announcer {
        color: #FF7F7F;
        font-size: 6.4rem;
        width: 524px;
        display: flex;
        justify-content: center;

        @include g.media-query-sp {
          font-size: 2.4rem;
        }
      }
      
      &--voiceactor {
        color: #956870;
        font-size: 6.4rem;
        width: 524px;
        display: flex;
        justify-content: center;

        @include g.media-query-sp {
          font-size: 2.4rem;
        }
      }
    }

    .section-description {
      font-size: 2rem;
      font-weight: 600;
      line-height: 1.6;
      text-align: center;
      margin-top: 24px;

      @include g.media-query-sp {
        font-size: 1.4rem;
      }
    }

    .text-image {
      &--01 {
        width: 100%;
        max-width: 96px;

        @include g.media-query-sp {
          max-width: 48px;
        }
      }
    }

    .sns-icon-wrapper {
      border-radius: 4px;
      padding: 6px;
      max-width: 32px;
    
      @include g.media-query-sp {
        position: absolute;
        right: 0;
        top: 2px;
      }
    }
  }

  //------------------------
  //イベント参加方法
  //------------------------

  &-participate {
    background-color: #956870;

    .section-heading {
      font-size: 7.2rem;
      line-height: 1;
      margin-top: 20px;
      
      @include g.media-query-sp {
        margin-top: 10px;
        max-width: 268px;
      }
    }

    .section-lead {
      font-size: 1.6rem;
      margin-top: 40px;
      
      @include g.media-query-sp {
        margin-top: 20px;
      }
    }

    .section-notes {
      position: relative;
      color: #fff;
      font-size: 2rem;
      padding-left: 40px;

      @include g.media-query-sp {
        font-size: 1.2rem;
        padding-left: 23px;
      }

      &::before{
       content: '';
       position: absolute;
       left: 0;
       width: 32px;
       height: 32px;
       background-image: url(../img/exclamation_icon.svg);
       background-size: contain;
       background-repeat: no-repeat;

       @include g.media-query-sp {
        width: 20px;
        height: 20px;
      }
      }
    }

    .unit {

      &-wrapper {
        display: flex;
        flex-direction: column;
        gap: 40px;
        margin-top: 48px;
        
        @include g.media-query-sp {
         gap: 20px;
         margin-top: 32px;
       }
      }

      &-item {
        background-color: #fff;
        // padding: 24px;
        border-radius: 10px;
        position: relative;
      }

      &-item:not(.unit-item:last-child) {
        &::after {
          position: absolute;
          background-image: url(../img/arrow_down_01.svg);
          width: 48px;
          height: 24px;
          content: '';
          bottom: -30px;
          left: 50%;
          transform: translateX(-50%);
          background-size: contain;

          @include g.media-query-sp {
            width: 24px;
            height: 12px;
            bottom: -16px;
          }
        }
      }
    }
  }
    
  //------------------------
  //プレゼント
  //------------------------

  &-present {
    background-color: #956870;

    .section-heading {
      font-size: 7.2rem;
      line-height: 1;
      
      @include g.media-query-sp {
        font-size: 3.2rem;
      }
    }

    .section-lead {
      font-size: 2.4rem;
      margin: 20px 0 24px;

      @include g.media-query-sp {
        font-size: 1.6rem;
        margin-bottom: 0;
      }
    }

    .arrow-svg {
      margin-left: 60px;
      margin-bottom: 10px;

      @include g.media-query-sp {
        width: 27px;
        margin: 0;
      }
    }

    .section-sub-heading {
      font-size: 4.8rem;

      @include g.media-query-sp {
        font-size: 3.2rem;
      }
    }

    .arrow-icon-one {
      margin: auto;
      padding: 8px 0 4px;
      
      @include g.media-query-sp {
        padding: 4px 0 2px;
        width: 12px;
      }
    }

    
    .ranking-contents-area {
        
      margin: 48px 0 80px;
      
      @include g.media-query-sp {
        margin: 32px 0 40px;
      }

      .column-card--voiceactor {
        padding-top: 40px;

        @include g.media-query-sp {
          padding-top: 20px;
        }
      }
    }

    .card-inner {
      padding: 32px;
      display: none;
      color: #fff;
      border-radius: 0 10px 10px 10px;
      
      @include g.media-query-sp {
        padding: 20px;
      }

      &--announcer {
        background-color: #FF7F7F;
      }

      &--voiceactor {
        background-color: #DC8FD7;
      }

      .column-ranking {
        gap: 24px;

        @media screen and (max-width:786px) {
          flex-direction: column;
          gap: 16px;
        }
      }

      .card {
        &-name {
          font-size: 4rem;
          line-height: 1.2;
          font-weight: 900;

          @include g.media-query-sp {
            font-size: 2.4rem;
            line-height: 1.5;
          }
        }

        &-point {
          font-size: 1.6rem;
          font-weight: 700;
          margin: 20px 0 16px;
          position: relative;
          padding-left: 28px;

          &::before {
            content: '';
            position: absolute;
            background-image: url(../img/icon_04.svg);
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 100% 100%;
            width: 24px;
            height: 24px;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
          }
        }

        &-ranking {
          width: calc((100% - 96px) / 5);

          @media screen and (max-width:786px) {
            width: 100%;
          }
        }

        &-ranking-title {
          font-size: 1.4rem;
          position: relative;
          display: inline-block;
          padding: 0 2px 2px;
          font-weight: 900;

          &::before {
            content: '';
            position: absolute;
            border-bottom: solid 3px #fff;
            width: 100%;
            bottom: -2px;
            left: 0;
          }
        }

        &-ranking-text {
          font-size: 1.4rem;
          padding-top: 16px;
          border-top: solid 1px #fff;
          font-weight: 700;
        }
      }
    }

    .card-inner.is-active {
      display: block;
      margin-top: 20px;
      position: relative;
      
      @include g.media-query-sp {
        margin-top: 10px;
      }
    }

    .sns-icon-ranking {
      position: absolute;
      right: 32px;
      top: 32px;
      width: 40px;
      height: 40px;
      border-radius: 5px;
      padding: 7px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      @include g.media-query-sp {
        width: 32px;
        height: 32px;
        padding: 5px;
        right: 20px;
        top: 20px;
      }

      &:hover {
        opacity: 0.6;
      }
    }

    .column-item.current {
      position: relative;
      border-radius: 10px 10px 0 0;

      &::before {
        content: "";
        width: 100%;
        bottom: -27px;
        left: 0;
        height: 28px;
        position: absolute;

        @include g.media-query-sp {
          bottom: -17px;
          height: 18px;
        }
      }
    }

    .column-item--announcer.current::before {
      background-color: #FF7F7F;
    }

    .column-item--voiceactor.current::before {
      background-color: #DC8FD7;
    }

    .all-benefits {
      border: #fff solid 1px;
      text-align: center;
      padding: 12px;
      margin-top: 20px;

      &-body {
        font-size: 1.4rem;
      }

      &-text {
        line-height: 1.42;
      }

      &-text + .all-benefits-text {
        position: relative;
        font-weight: 700;

        &::before {
          content: '';
          border-left: 3px solid #fff;
          height: 24px;
          margin: 0 16px;
        }
      }

      &-text-bold {
        font-weight: 900;
      }
    }

    .button-wrap-app {
      margin-top: 24px;
    }
  }
  
  // -----------------
  // ポイント
  // -----------------
  &-point {
    background-color: #582059;
    position: relative;
    
    &::before {
      content: '';
      width: 820px;
      height: 810px;
      background-image: url(../img/bg_01.png);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 100% 100%;
      position: absolute;
      right: 0;
      z-index: 0;
      top: 0;

      @include g.media-query-sp {
        width: 370px;
        height: 667px;
        right: -158px;
      }
    }

    .section-heading {
      font-size: 7.2rem;
      
      @include g.media-query-sp {
        font-size: 3.2rem;
      }
    }

    .section-lead {
      font-size: 2.4rem;
      margin-top: 20px;
      
      @include g.media-query-sp {
        font-size: 1.6rem;
      }
    }

    .detail-wrapper {
      background-color: #DC8FD7;
      padding: 32px 20px;
      margin-top: 48px;
      
      @include g.media-query-sp {
        margin-top: 32px;
      }
    }

    .detail-inner {
      border: solid 4px #582059;
      border-radius: 10px;
      max-width: 840px;
      margin: auto;
      padding: 32px;
      
      @include g.media-query-sp {
        padding: 12px;
      }

      +.detail-inner {
        margin-top: 48px;
        position: relative;
        
        @include g.media-query-sp {
          margin-top: 24px;
        }
        
        &::before {
          content: '';
          top: -68px;
          left: 50%;
          transform: translateX(-50%);
          position: absolute;
          background-image: url(../img/icon_plus_01.png);
          width: 80px;
          height: 80px;
          background-repeat: no-repeat;
          background-size: contain;

          @include g.media-query-sp {
            width: 44px;
            height: 44px;
            top: -38px;
          }
        }
      }
    }
    
    .detail-heading {
      margin-bottom: 32px;
      
      @include g.media-query-sp {
        margin-bottom: 16px;
      }

      &-marker {
        background: linear-gradient(transparent 70%, #fff 50%);
        padding: 0 6px;
        background-position-y: -8px;
      }
    }

    .detail-text {
      color: #fff;
      font-size: 2rem;
      line-height: 1.6;
      text-align: center;
      background-color: #582059;
      border-radius: 10px;
      padding: 8px;
      font-weight: 900;

      @include g.media-query-sp {
        font-size: 1.6rem;
        line-height: 1.5;
      }
    }
  }
}

.inner {
  max-width: 1120px;
  margin: auto;
  padding: 0 20px;
  position: relative;
}

.sns-icon-wrapper {
  background: #FFF;
  box-shadow: 0px 2.667px 5.333px 0px rgba(0, 0, 0, 0.16);
  display: block;
  margin-left: auto;

  @include g.media-query-sp {
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.32);
  }
}

.profile {

  &-name {
    color: #fff;
    font-size: 3.2rem;
    font-weight: 900;

    @include g.media-query-sp {
      font-size: 2.4rem;
      margin-bottom: 8px;
    }
  }

  &-dl {
    display: flex;
    font-size: 1.4rem;
    line-height: 1.4;
    width: 100%;
    border-bottom: #fff solid 1px;
    padding: 8px 0 3px;
  }

  &-dt , &-dd {
    color: #fff;
  }

  &-dt {
    width: 80px;
  }

  &-dd {
    position: relative;
    flex: 1;
    padding-left: 24px;

    .profile-icon {
      line-height: 1.6;
    }
  }

  &-icon {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);

    @include g.media-query-sp {
      top: 0;
      transform: none;
    }
  }

  &-text-wrapper {
    width: 300px;

    @include g.media-query-sp {
      position: relative;
      width: 100%;
    }
  }
}

.card-item {
  gap: 20px;

  picture {
    @include g.media-query-sp {
      width: 100%;
    }
  }
}

    
// -----------------
// ゲームガイド
// -----------------
.contents-wrapper-guid {
  background-color: #DC8FD7;

  .contents {
    
      &-inner {
        position: relative;

        @include g.media-query-pc {
          display: flex;
        }
      }
    
      &-image {
        background-image: url(../img/banner_image_01.png);
        content: '';
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        
        @include g.media-query-pc {
          height: 320px;
          width: calc(100% / 2);
        }
        
        @include g.media-query-sp {
          width: 100%;
          height: 100%;
          position: absolute;
        }
      }
    
      &-lead {
        font-size: 2rem;
        line-height: 1.6;
        color: #fff;
        margin: 20px 0 8px;
        
        @include g.media-query-sp {
          font-size: 1.6rem;
          margin: 16px 0 8px;
        }
      }
    
      &-button {
        width: 100%;
        padding: 10px 0;
        display: inline-block;
        
        @include g.media-query-sp {
          padding: 13px 0;
        }

        &:hover {
          opacity: 0.6;
        }
        
        &-wrapper {
          border: solid 2px #F28C0F;
          border-radius: 24px;
          max-width: 280px;
          text-align: center;
          
          @include g.media-query-sp {
            max-width: 240px;
            margin: auto;
          }
        }
      }
    
      &-text-wrapper {
        width: 560px;
        padding: 68px 80px;

        @include g.media-query-sp {
          width: 100%;
          padding: 18px 20px;
          position: relative;
          z-index: 1;
          text-align: center;
        }
      }

  }
}

// -----------------
// 禁止事項
// -----------------

.note-area {
  background-color: #956870;
  padding: 80px 0;
  
  @include g.media-query-sp {
    padding: 40px 0 60px;
  }

  .text-image-svg {
    @include g.media-query-sp {
      max-width: 82px;
    }
  }

  .modal-button {

    &-note{
      color: #F28C0F;
      background-color: #fff;
      width: 100%;
      border-radius: 36px;
      border: none;
      padding: 24px 18px;
      display: block;
      max-width: 320px;
      margin: auto;
      box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);

      @include g.media-query-sp {
        padding: 26px 18px;
        box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.32);
      }
  
      &:hover {
        opacity: 0.6;
        cursor: pointer;
      }
    }

    &-rule , &-info {
      border-bottom: 1px solid #fff;
      padding-bottom: 8px;
    }

    &-wrapper {
      display: flex;
      gap: 35px;
      justify-content: center;
      margin-top: 38px;
      
      @include g.media-query-sp {
        flex-direction: column;
        align-items: center;
        gap: 16px;
        
      }
    }
  }
}

// -----------------
// モーダル共通
// -----------------

.modaal-close {
  display: none;
}

.modaal-wrapper.modaal-start_fade {
  pointer-events: none;
}

.modal-content {
  display: none;
}

.modaal-container {
  max-width: 1080px;
}

.modaal-content-container {
  background-color: #956870;
  width: 100%;
  position: relative;
}

.modal-text {
  color: #fff;
}

.modal-close-button {
  content: '';
  position: absolute;
  top: 32px;
  right: 32px;
  width: 40px;
  height: 32px;
  
  @include g.media-query-sp {
    top: 16px;
    right: 16px;
  }

  &::before,&::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50% , -50%);
    width: 1px; /* 棒の幅（太さ） */
    height: 42px; /* 棒の高さ */
    background: #fff;
  }

  &::before {
    transform: translate(-50%,-50%) rotate(45deg);
  }

  &::after {
    transform: translate(-50%,-50%) rotate(-45deg);
  }

  &:hover {
    opacity: 0.6;
    cursor: pointer;
  }
}

.modal-list {
  padding-left: 1em;
  text-indent: -1em;
  
  &-number {
    padding-left: 1.3em;
    text-indent: -1.3em;
  }
}


.modaal-wrapper {

  ::-webkit-scrollbar {
    background: rgba(0,0,0,0.5);
    width: 15px;
    height: 15px;
    border-radius: 20px;
    
    @include g.media-query-sp {
      width: 2px;

    }
  }
  ::-webkit-scrollbar-thumb {
    background-color: #fff;
    border-radius: 20px;
  }
}


// ---------------------
// モーダルローディング
// ---------------------

.page-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  transition: opacity 2s ease;
}

.page-overlay.hidden {
  opacity: 0;
  z-index: -1;
}

.modaal-content-container:has(.modal-loading-inner) {
  padding: 96px 64px 64px;
  
  @include g.media-query-sp {
    padding: 96px 16px 64px;
    
  }
}

.modal-loading-inner {

  .copy {
    color: #fff;
    font-size: 1rem;
    text-align: center;
    font-weight: 500;
    margin-top: 16px;
    letter-spacing: .01em;
    line-height: 1;
  }

  .modal-loading-image {
    margin: auto;
    max-width: 468px;

    @include g.media-query-sp {
      max-width: 234px;
    }
  }

  .modal-text {
    font-size: 3.2rem;
    text-align: center;
    font-weight: 900;
    margin-top: 32px;

    @include g.media-query-sp {
      font-size: 2rem;
    }
  }
}


// ---------------------
// モーダル注意事項
// ---------------------

  
.modaal-content-container:has(.modal-note-inner) {
  padding: 96px 44px 64px 64px;

  @include g.media-query-sp {
    padding: 64px 16px;
  }
}

.modaal-inner-wrapper {
  @include g.media-query-sp {
    padding: 40px 20px;
  }
}

.modal-note-inner {
  max-height: 560px;
  overflow-y: auto;

  .modal-title {
    font-size: 3.2rem;
    font-weight: 900;
    line-height: 1.25;
    padding-bottom: 16px;

    @include g.media-query-sp {
      font-size: 2rem;
      line-height: 1.6;
    }
  }

  .modal-list , .modal-text-normal , .modal-list-number {
    font-size: 2rem;
    font-weight: 700;
    line-height: 1.6;
    padding-top: 16px;

    @include g.media-query-sp {
      font-size: 1.6rem;
      padding-top: 8px;
    }

    &-wrapper {
      margin-bottom: 16px;
    }
  }

  .modal-text-notes {
    font-size: 1.6rem;
    font-weight: 500;
    
    @include g.media-query-sp {
      font-size: 1.4rem;
    }
  }

  .modal-section-wrapper {
    display: flex;
    flex-direction: column;
    gap: 80px;
    
    @include g.media-query-sp {
      gap: 40px;
    }
  }

  .modal-section {
    padding-right: 20px;
    
    @include g.media-query-sp {
      padding-right: 10px;
    }
  }
}


// ---------------------
// アコーディオン
// ---------------------

.unit-item-body {
  padding: 24px;
  position: relative;

  @include g.media-query-sp {
    padding: 14px;
  }

  img {
    padding-right: 60px;
    
    @include g.media-query-sp {
      padding-right: 16px;

    }
  }

  &:hover {
    opacity: 0.6;
    cursor: pointer;
  }

  &::before , &::after {
    content: '';
    display: inline-block;
    width: 40px;
    height: 4px;
    background-color: #FF7F7F;
    position: absolute;
    right: 20px;
    top: 50%;
    border-radius: 10px;

    @include g.media-query-sp {
      width: 16px;
      height: 2px;
      right: 12px;
    }
  }

  &::before {
    transform: translateY(-50%);
    transition: opacity 0.6s;
  }

  &::after {
    transform: translateY(-50%) rotate(90deg);
    transition: transform 0.6s;
  }
}


.unit-item-body.open::before {
  opacity: 0;
}

.unit-item-body.open::after {
  transform: translateY(-50%) rotate(180deg);
}

.accordion-contents {
  display: none;
  background-color: #DC8FD7;
  padding: 32px 64px 64px;
  border-radius: 0 0 10px 10px;
  
  @include g.media-query-sp {
    padding: 16px 16px 32px;
  }
}

.accordion-text {
  color: #fff;
  font-size: 2.4rem;
  font-weight: 700;
  text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.32);

  @include g.media-query-sp {
    font-size: 1.6rem;
  }
}

.accordion-box {
  background-color: #956870;
  max-width: 840px;
  margin: 34px auto 0;
  border-radius: 10px;
  
  @include g.media-query-sp {
    margin: 16px auto 0;
  }

  .accordion-band {
    @include g.media-query-sp {
      width: 132px;
    }
  }
}

.accordion-sub-title {
  color: #fff;
  font-size: 1.8rem;
  font-weight: 900;
  line-height: 1;
  padding-left: 12px;

  @include g.media-query-sp {
    font-size: 1.6rem;
  }
}

.accordion-sub-title-wrapper {
  display: flex;
  align-items: center;
  border: 2px solid #fff;
  padding: 10px;
  border-radius: 5px;
  width: fit-content;
}

.accordion-sub-title-icon {
  width: 20px;

  @include g.media-query-sp {
    width: 16px;
  }
}

.accordion-container {
  display: flex;
  gap: 24px;

  @include g.media-query-sp {
    flex-direction: column;
    gap: 12px;
  }
}

.accordion-description {
  font-size: 2rem;
  font-weight: 900;
  line-height: 1.6;
  color: #fff;

  @include g.media-query-sp {
    font-size: 1.6rem;
    line-height: 1.5;
  }
  
  &-wrapper {
    flex: 1;
  }

  &-marker {
    background: linear-gradient(transparent 60%,#FF7F7F 40% 100%);
    display: inline;
    line-height: 1.6;
  }
}

.marker-text-wrapper {
  margin: 16px 0 24px;
  
  @include g.media-query-sp {
    margin: 8px 0 16px;
  }
}

.accordion-wrapper {
  padding: 0 32px;
    
  @include g.media-query-sp {
    padding: 0 16px;
  }
}

.accordion-inner {
  padding: 24px 0 32px;

  @include g.media-query-sp {
    padding: 24px 0;
  }

  &-one {
    padding: 16px 0 24px;
  }

  &-two {
    padding: 24px 0 32px;

    @include g.media-query-sp {
      padding: 24px 0 32px;
    }
  }

  +.accordion-inner {
    border-top: 1px solid #fff;
  }
}

.accordion-text-normal {
  font-size: 1.6rem;
  color: #fff;
  font-weight: 700;
    
  @include g.media-query-sp {
    font-size: 1.4rem;
  }
}


.accordion-notes {
  color: #fff;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.4;
  text-indent: -1em;
  padding-left: 1em;

  @include g.media-query-sp {
    font-size: 1.2rem;
    line-height: 1.5;
  }

  +.accordion-notes {
    margin-top: 4px;
  }
}

.accordion-02 {
  .accordion-notes-wrapper {
    margin-top: 34px;

    @include g.media-query-sp {
      margin-top: 16px;
    }
  }
}

.accordion-number {
  position: relative;
  font-size: 2rem;
  color: #fff;
  padding-left: 48px;

  @include g.media-query-sp {
    font-size: 1.6rem;
    padding-left: 24px;
  }

  +.accordion-number {
    margin-top: 8px;
  }

  &::before {
    counter-increment: number 1; /* counter-resetの名前を指定、+1づつ増やす。*/
    content: counter(number) ""; /* 番号の後に、ドットを付ける場合に指定する。*/
    display: inline-block;
    background: #FF7F7F;
    border-radius: 50%;
    font-weight: 700;
    font-size: 1.8rem;
    color: white;
    left: 0;
    width: 28px;
    height: 28px;
    line-height: 1.55;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
    position: absolute;

    @include g.media-query-sp {
      width: 20px;
      height: 20px;
      font-size: 1.4rem;
      line-height: 1.42;
      top: 3px;
      transform: none;
    }
  }
  &-wrapper {
    counter-reset: number 0;
  }
}

.accordion-03 {
  .accordion-text-normal {
    margin: 16px 0 32px;
    position: relative;
    padding-left: 33px;
    
    @include g.media-query-sp {
      padding-left: 24px;
      margin: 16px 0 24px
    }
  }
}

.fa-exclamation-triangle {
  font-size: 2rem;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);

  @include g.media-query-sp {
    font-size: 1.6rem;
    top: 3px;
    transform: none;
  }
}

.accordion-04 {
  .accordion-text-mark {
    margin: 16px 0 32px;
    position: relative;
    background-color: #FF7F7F;
    display: inline-block;
    padding: 4px 4px 4px 33px;

    @include g.media-query-sp {
      padding-left: 24px;
      margin: 8px 0 16px;
      line-height: 1.42;
    }
  }
  .fa-exclamation-triangle {
    font-size: 2rem;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  
    @include g.media-query-sp {
      font-size: 1.6rem;
      top: 6px;
      transform: none;
      left: 6px;
    }
  }
}

//-------------------
// 中間報告
//-------------------
.point-item {
  width: calc((100% - 40px) / 2);
  @include g.media-query-sp {
    width: 100%;
    max-width: 295px;
    margin: auto;
  }
  &--idol {
    @include g.media-query-sp {
      order: 0;
    }
  }
  &--actress {
    @include g.media-query-sp {
      order: 1;
    }
  }
}