// color
$key-color: rgb(145, 121, 253);
$accent-color: #d68d3d;
$point-color: #68a095;
$font-base-color: #181818;

//breakpoints
$breakpoints-spfirst: (
  'xs': 'screen and (min-width: 480px)',
  'sm': 'screen and (min-width: 768px)',
  'md': 'screen and (min-width: 992px)',
  'lg': 'screen and (min-width: 1200px)',
) !default;
$breakpoints-pcfirst: (
  'xs': 'screen and (max-width: 479px)',
  'sm': 'screen and (max-width: 767px)',
  'md': 'screen and (max-width: 991px)',
  'lg': 'screen and (max-width: 1199px)',
) !default;

//font
$font-noto-sans: "Noto Sans JP", "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;


//font-weight
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-black: 900;

//line-height
$line-height-long: 2;
$line-height-middle: 1.5;
$line-height-short: 1.4;
$line-height-normal: 1.2;