@use "../global" as g;

html {
  font-size: 62.5%;// font-sizeの10pxを1remにするため
}

* {
  font-family: g.$font-noto-sans;
}

a:hover {
  opacity: 0.6;
  cursor: pointer;
}