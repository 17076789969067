@use "../global" as g;

.column {

  display: flex;
  flex-wrap: wrap;

  &-two {
    gap: 40px;

    @include g.media-query-sp {
      gap: 20px;
    }

    .column-item {
      width: calc((100% - 40px) / 2);

      @include g.media-query-sp {
        width: 100%;
        flex-direction: column;
        max-width: 295px;
        margin: auto;
      }
    }

    .column-image {
      object-fit: cover;
      aspect-ratio: 8 / 9;
      border-radius: 8px;
      width: 100%;
      
      @include g.media-query-pc {
        max-width: 160px;
      }
      
      @include g.media-query-sp {
        aspect-ratio: 1 / 1;
      }
    }

    .column-announcer {
      background-color: #FF7F7F;
      border-radius: 8px;
      padding: 20px;

      @include g.media-query-sp {
        order: 0;
      }
    }

    .column-voiceactor {
      background-color: #DC8FD7;
      border-radius: 8px;
      padding: 20px;

      @include g.media-query-sp {
        order: 1;
      }
    }
  }

  &-three {
    gap: 10px;
    
    @include g.media-query-sp {
      gap: 5px;
    }

    .column-item {
      width: calc((100% - 20px) / 3);
      
      @include g.media-query-sp {
        width: calc((100% - 5px) / 2);
      }
    }
  }

  &-four {
    gap: 40px;

    .column-item {
      width: calc((100% - 120px) / 4);
    }
  }

  &-five {
    gap: 20px;
    
    @include g.media-query-sp {
      row-gap: 20px;
      column-gap: 5px;
    }

    .column-item {
      // width: calc((100% - 80px) / 5);
      width: calc((100% - 60px) / 4);
      padding: 8px;
      border-radius: 10px;

      &:hover {
        opacity: 0.6;
        cursor: pointer;
      }
      
      @include g.media-query-sp {
        // width: calc((100% - 22px) / 5);
        width: calc((100% - 15px) / 4);
        padding: 4px;
        border-radius: 5px;
      }
      
      &--announcer {
        background-color: #FF7F7F;
      }
      
      &--voiceactor {
        background-color: #DC8FD7;
      }
    }

    .column-image {
      // max-width: 184px;
      max-width: 239px;
      object-fit: cover;
      aspect-ratio: 1 / 1;
      border-radius: 8px;
      margin: auto;
    }
  }

  &-profile {
    margin-top: 48px;

      @media screen and (max-width:1138px) and (min-width:768px) {
        max-width: 700px;
        margin: 48px auto 0;
      }

      @include g.media-query-sp {
        margin-top: 32px;
        flex-direction: column;
      }

    .card-item {
      @media screen and (max-width:1138px) and (min-width:768px) {
        justify-content: center;
      }
    }

  }

  // &-card {
  //   margin: 48px 0 80px;
    
  //   @include g.media-query-sp {
  //     margin: 32px 0 40px;
  //   }
  // }

  &-image {
    width: 100%;
  }


  &-description {
    font-size: 1.6rem;
  }
}