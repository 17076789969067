@use "../global" as g;

@include g.media-query-pc { /* pc非表示 */
  .u-hidden-pc {
    display: none;
  }
}

@include g.media-query-sp { /* sp非表示 */
  .u-hidden-sp {
    display: none;
  }
}